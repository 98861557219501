<template>
  <div>
    <navbar></navbar>
    <v-row justify="center">
      <v-col cols="12" sm="10">
        <h1 style="color: black">{{ $t("pageConfidentialite.title") }}</h1>
        <p>{{ $t("pageConfidentialite.lastUpdate") }}</p>

        <!-- Section 1 -->
        <h2>{{ $t("pageConfidentialite.sections.generalInfo.title") }}</h2>
        <p>{{ $t("pageConfidentialite.sections.generalInfo.content") }}</p>
        <p>
          <strong>{{
            $t("pageConfidentialite.sections.generalInfo.dataController")
          }}</strong
          ><br />
          {{ $t("pageConfidentialite.sections.generalInfo.company") }}<br />
          {{ $t("pageConfidentialite.sections.generalInfo.address") }}<br />
          {{ $t("pageConfidentialite.sections.generalInfo.email") }}
        </p>

        <!-- Section 2 -->
        <h2>{{ $t("pageConfidentialite.sections.dataCollection.title") }}</h2>
        <p>{{ $t("pageConfidentialite.sections.dataCollection.intro") }}</p>
        <ul>
          <li>
            {{ $t("pageConfidentialite.sections.dataCollection.points.contactForm") }}
          </li>
          <li>
            {{ $t("pageConfidentialite.sections.dataCollection.points.accountCreation") }}
          </li>
          <li>
            {{ $t("pageConfidentialite.sections.dataCollection.points.browserLang") }}
          </li>
          <li>
            {{ $t("pageConfidentialite.sections.dataCollection.points.analytics") }}
          </li>
        </ul>
        <p>{{ $t("pageConfidentialite.sections.dataCollection.disclaimer") }}</p>

        <!-- Section 3 -->
        <h2>{{ $t("pageConfidentialite.sections.dataUsage.title") }}</h2>
        <p>{{ $t("pageConfidentialite.sections.dataUsage.intro") }}</p>
        <ul>
          <li>
            {{ $t("pageConfidentialite.sections.dataUsage.points.contactRequest") }}
          </li>
          <li>{{ $t("pageConfidentialite.sections.dataUsage.points.accountAccess") }}</li>
          <li>{{ $t("pageConfidentialite.sections.dataUsage.points.langDisplay") }}</li>
          <li>
            {{ $t("pageConfidentialite.sections.dataUsage.points.analyticsUsage") }}
          </li>
        </ul>

        <!-- Section 4 -->
        <h2>{{ $t("pageConfidentialite.sections.dataSecurity.title") }}</h2>
        <p>{{ $t("pageConfidentialite.sections.dataSecurity.intro") }}</p>
        <ul>
          <li>
            {{ $t("pageConfidentialite.sections.dataSecurity.points.secureStorage") }}
          </li>
          <li>
            {{
              $t("pageConfidentialite.sections.dataSecurity.points.passwordEncryption")
            }}
          </li>
          <li>
            {{ $t("pageConfidentialite.sections.dataSecurity.points.restrictedAccess") }}
          </li>
          <li>
            {{ $t("pageConfidentialite.sections.dataSecurity.points.ipAnonymization") }}
          </li>
        </ul>

        <!-- Section 5 -->
        <h2>{{ $t("pageConfidentialite.sections.dataRetention.title") }}</h2>
        <ul>
          <li>
            {{ $t("pageConfidentialite.sections.dataRetention.points.contactFormData") }}
          </li>
          <li>
            {{ $t("pageConfidentialite.sections.dataRetention.points.accountData") }}
          </li>
          <li>
            {{ $t("pageConfidentialite.sections.dataRetention.points.analyticsData") }}
          </li>
        </ul>

        <!-- Section 6 -->
        <h2>{{ $t("pageConfidentialite.sections.userRights.title") }}</h2>
        <p>{{ $t("pageConfidentialite.sections.userRights.intro") }}</p>
        <ul>
          <li>
            {{ $t("pageConfidentialite.sections.userRights.points.deleteAccount") }}
          </li>
          <li>{{ $t("pageConfidentialite.sections.userRights.points.editAccount") }}</li>
        </ul>
        <p>{{ $t("pageConfidentialite.sections.userRights.contactInfo") }}</p>

        <!-- Section 7 -->
        <h2>{{ $t("pageConfidentialite.sections.cookies.title") }}</h2>
        <p>{{ $t("pageConfidentialite.sections.cookies.intro") }}</p>
        <a href="https://policies.google.com/privacy">{{
          $t("pageConfidentialite.sections.cookies.linkText")
        }}</a>

        <!-- Section 8 -->
        <h2>{{ $t("pageConfidentialite.sections.policyChanges.title") }}</h2>
        <p>{{ $t("pageConfidentialite.sections.policyChanges.content") }}</p>

        <!-- Section 9 -->
        <h2>{{ $t("pageConfidentialite.sections.contact.title") }}</h2>
        <p>{{ $t("pageConfidentialite.sections.contact.intro") }}</p>
        <ul>
          <li>{{ $t("pageConfidentialite.sections.contact.points.company") }}</li>
          <li>{{ $t("pageConfidentialite.sections.contact.points.address") }}</li>
          <li>{{ $t("pageConfidentialite.sections.contact.points.email") }}</li>
        </ul>
      </v-col>
    </v-row>
    <foot class="mt100" />
  </div>
</template>

<script>
import foot from "@/components/FooterBis.vue";
import navbar from "@/components/NavBar.vue";
export default {
  components: {
    foot,
    navbar,
  },
};
</script>
